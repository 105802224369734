import React, { useState } from 'react';
import ReactHtmlParser from "react-html-parser";
import Fade from "react-reveal/Fade";
import styled from 'styled-components';
import { CentreWrapper, RealButton } from '../components/ButtonElement';
import { SupportPopup } from '../components/FormPopup';
import SEO from '../components/seo';
import { SupportTestimony } from '../components/Testimonials';
import { supportBanner } from '../images';
import Layout from "../layout/layout";
import { graphql } from "gatsby"

const sidebar = [
  {
    to: 'Immigration',
    title: 'Immigration Services'
  },
  {
    to: 'Relocation',
    title: 'Relocation Services'
  },
  {
    to: 'Insurance',
    title: 'Insurance'
  },
]

const FadeH1 = ({ children }) => {
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <Fade>
        <h1>{children}</h1>
      </Fade>
      <br/>
    </div>
  )
}

const Section = styled.div`
  margin: 40px 230px;
  max-width: 100%;

  @media (max-width: 1200px) {
    margin: 40px 100px;
  }

  @media (max-width: 900px) {
    margin: 40px 50px;
  }

  @media (max-width: 740px) {
    margin: 9px;
  }
`

export default function SupportServices ({data}) {
  const html = data.allWpPage.edges
  const Family = ReactHtmlParser(html[0].node.content)
  const Immigration = ReactHtmlParser(html[1].node.content)
  const StudyAbroad = ReactHtmlParser(html[2].node.content)
  const [formType, setFormType] = useState("")
  return (
      <div>
        <Layout sidebar={sidebar} pageTitle="Support Services" background={supportBanner} pageDescription="Complementary services for international students and their families">
          <SEO title="Support Services | Immigration | Relocation | Insurance" description="Study Links offers a range of complementary services for international students and their families, including immigration, relocation and insurance support."/>
          <Section id="Immigration">
            <FadeH1>Immigration Services</FadeH1>
            {Immigration.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#supportModal1"
              onClick={() => setFormType("Careers Advice")}
            >
              Contact us
            </RealButton>
          </CentreWrapper>
          <SupportPopup formType={formType} />
          </Section>
          <Section id="Relocation">
            <FadeH1>Relocation Services</FadeH1>
            {Family.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#supportModal1"
              onClick={() => setFormType("Careers Advice")}
            >
              Contact us
            </RealButton>
          </CentreWrapper>
          </Section>
          <Section id="Insurance">
            <FadeH1>Insurance</FadeH1>
            {StudyAbroad.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#supportModal1"
              onClick={() => setFormType("Careers Advice")}
            >
              Contact us
            </RealButton>
          </CentreWrapper>
          </Section>
          <SupportTestimony/>
        </Layout>
      </div>
  )
}

export const STUDYLINKS_SUPPORTSERVICES = graphql`
  query studylinks_supportservices {
    allWpPage(filter: {title: {in: ["api-studylinks-support services-Family Relocation Services", "api-studylinks-support services-Immigration Services", "api-studylinks-support services-Study Abroad Insurance"]}}, sort: {fields: title, order: ASC}) {
      edges {
        node {
          title
          content
        }
      }
    }
  }

`